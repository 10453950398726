import './App.css';
import { BrowserRouter} from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Experience from './components/Experience';
import Tech from './components/Tech';
import Contact from './components/Contact';
import StarsCanvas from './components/canvas/Stars';
import Works from './components/Works';
import Testimonials from './components/Testimonials';
import Blog from './components/Blog';
function App() {
  return (
    <BrowserRouter>
      <div className="relative z-0 bg-primary">
        <div className="bg-cover bg-no-repeat bg-center">
          <Navbar />
          <Hero/>
        </div>
        <About/>
        <Experience/>
        <Tech/>
        <Works/>
        <Blog/>
        <div className='relative z-0'>
          <Testimonials/>
          <Contact/>
          <StarsCanvas/>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
