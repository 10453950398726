export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "skills",
    title: "Skills",
  },
  {
    id: "projects",
    title: "Projects",
  },
  {
    id: "blog",
    title: "Blogs",
  },
  {
    id:"testimonials",
    title:"Testimonials"
  },
  {
    id: "contact",
    title: "Contact",
  },
];

export const services = [
  {
    title: "Full Stack Web Development",
    img: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/WebDevelopment.png",
  },
  {
    title: "UI / Front End Developer",
    img: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/FrontEnd.png",
  },
  {
    title: "Backend Developer",
    img: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/NodeJs+Icon.png",
  },
  {
    title: "Cloud Management",
    img: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/UiDesigner.png",
  },
];

export const technologies = [
  {
    name: "React JS",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Reactjs.png",
  },
  {
    name: "Next JS",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/nextjs.png",
  },
  {
    name: "HTML 5",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Html.png",
  },
  {
    name: "CSS 3",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Css.png",
  },
  {
    name: "JavaScript",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Javascript.png",
  },
  {
    name: "Tailwind CSS",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Tailwind.png",
  },
  {
    name: "Node JS",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Nodejs.png",
  },
  {
    name: "MongoDB",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Mongodb.png",
  },
  {
    name: "AWS",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Aws.png",
  },
  {
    name: "Supabase",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/supabase.png",
  },
  {
    name: "Three JS",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/threejs.png",
  },
  {
    name: "Git",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Git.png",
  },
  {
    name: "Docker",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Docker.png",
  },
  {
    name: "Jira",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/jira.png",
  },
  {
    name: "Notion",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/notion.jpeg",
  },
  {
    name: "APS",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/aps.png",
  },
  {
    name: "Figma",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/figma.png",
  },
  {
    name: "MS-SQL",
    icon: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/mssql.png",
  },
];

export const experiences = [
  {
    role: "Quality Auditor",
    company_name: "Lucas TVS",
    points: [
      "Conduct Line Audits on daily basis for the product manufactured.",
      "Ensure the manufacturing process are not skipped.",
      "Document the readings to check whether the machining are running within the allowed specifcations.",
    ],
    period: "Jan,2020 to Mar,2021",
    img: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Auditor.png",
  },
  {
    role: "Software Testing Intern",
    company_name: "Pixel & Mortar",
    points: [
      "Check for Bugs in the product daily.",
      "Report the bugs to the lead and document the process.",
      "Write test cases for the work process.",
    ],
    period: "Oct,2021 to Dec,2021",
    img: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Tester.png",
  },
  {
    role: "Automation Engineer",
    company_name: "Pixel & Mortar",
    points: [
      "Automate Work flow process for faster results.",
      "Run Regression and Sanity Testing for the product for bugs.",
      "Report Bugs and documnet the testing results.",
      "Automate workflow process for the internal team.",
    ],
    period: "Jan,2022 to Sep,2022",
    img: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Selenium+Icon.png",
  },
  {
    role: "Lead Software Developer",
    company_name: "Pixel & Mortar",
    points: [
      "Developing and maintaining web applications using React.js and other related technologies.",
      "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
    period: "Oct,2022 to Mar, 2024",
    img: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Developer.png",
  },
  {
    role: "Lead Autodesk Forge & Software Developer",
    company_name: "USAM Technologies",
    points: [
      "Developing and maintaining web applications using React.js and other related technologies.",
      "Implement Autodesk Platform Services using Autodesk API's.",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Automate and improve the development and deployment process for releases",
      "Establish monitoring systems to improve visibility on infrastructure performance",
      "Create an efficient architecture that increases efficiency and reduces running costs",
      "Participating in code reviews and providing constructive feedback to other developers.",
      "Implement Project Management amongst the team to track the project's progress.",
      "Train freshers and enhance them in coding knowledge."
    ],
    period: "Apr,2024 to Present",
    img: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Developer.png",
  },
];

export const Projects = [
  {
    name: "Image-Hotspots",
    description:
      "Spearheaded the development of an innovative feature set, facilitating the effortless integration of multiple dynamic hotspots onto images that includes the ability to attach contextual comments and predefined settings to each hotspot, all complemented by a sophisticated scalable mechanism that seamlessly adapts hotspot positioning in response to changes in screen dimensions.",
    tags: [
      {
        name: "React",
        color: "blue-text-gradient",
      },
      {
        name: "Tailwind Css",
        color: "pink-text-gradient",
      },
    ],
    image:"https://portfolio20072023.s3.ap-south-1.amazonaws.com/ImageHotspot.png",
    github_src: "https://github.com/JoelRevington1305/React-Image-Hotspots",
  },
  {
    name: "Netflix Clone",
    description:
      "Netflix is one of the biggest streaming Platforms across the world, which streams high defination quality content of movies and webseries of various languages under different genres. Developed a clone of the Netflix platform with a similar UI exihibited by the streamimg platform using React and Tailwind and authentication using Firebase(a google provider for mobile and web).",
    tags: [
      {
        name: "React",
        color: "blue-text-gradient",
      },
      {
        name: "Firebase",
        color: "green-text-gradient",
      },
      {
        name: "Tailwind Css",
        color: "pink-text-gradient",
      },
      {
        name: "TMDB",
        color: "yellow-text-gradient",
      },
    ],
    image: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Netflix.png",
    github_src: "https://github.com/JoelRevington1305/Netflix-clone",
  },
  {
    name: "Seamless Search",
    description:
      "Spearheaded a highly responsive and efficient search component developed using React, Tailwind CSS, MongoDB, and a .bat file for automating the startup process. This component is designed to provide a seamless and intuitive user experience, allowing users to search through a product database with dynamic filtering and sorting capabilities.",
    tags: [
      {
        name: "React",
        color: "blue-text-gradient",
      },
      {
        name: "Mongo DB",
        color: "green-text-gradient",
      },
      {
        name: "Tailwind Css",
        color: "pink-text-gradient",
      },
    ],
    image: "https://portfolio20072023.s3.ap-south-1.amazonaws.com/searchbar.jpg",
    github_src: "https://github.com/JoelRevington1305/Product-Search",
  },
];
