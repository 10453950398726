/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { motion } from "framer-motion";
import { styles } from "../styles";
import { SectionWrapper } from "../section";
import {textVariant, fadeIn } from "../utils/motion";
import Tilt from 'react-tilt'

const BlogCard = ({
  index,
  title,
  link,
  image,
  readTime
}) => (
  <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
    <Tilt
      opions={{ max: 45, scale: 1, speed: 450 }}
      className="bg-tertiary p-4 rounded-2xl sm:w-[360px] cursor-pointer md:w-[500px] w-full"
    >
      <div className="relative w-full h-[230px]" onClick={() => window.open(link, "_blank", "noreferrer")}>
        <img
          src={image}
          alt=""
          className="w-full h-full object-cover rounded-md"
        />
      </div>
      <div className="mt-5 gap-1 flex flex-col" onClick={() => window.open(link, "_blank", "noreferrer")}>
        <div className="text-white font-bold text-xl">{title}</div>
        <div className="text-white flex w-full justify-end text-base">{readTime}&nbsp;read</div>
      </div>
    </Tilt>
  </motion.div>
);

const Blog = () => {
  const blogs = [
    {
      id:1,
      title:"Building a Custom React Image Hotspot from scratch",
      link:"https://joetechinsights.hashnode.dev/custom-react-image-hotspot-1",
      image:"https://portfolio20072023.s3.ap-south-1.amazonaws.com/hotspots.jpg",
      readTime:"10 min"
    },
    {
      id:2,
      title:"What improvises Next JS over React JS for web-development?",
      link:"https://joetechinsights.hashnode.dev/developers-point-on-nextjs-over-reactjs",
      image:"https://portfolio20072023.s3.ap-south-1.amazonaws.com/next.avif",
      readTime:"6 min"
    },
    {
      id:3,
      title:"Starting your career as a Developer in the Tech Industry",
      link:"https://joetechinsights.hashnode.dev/software-development",
      image:"https://portfolio20072023.s3.ap-south-1.amazonaws.com/developer.avif",
      readTime:"7 min"
    },
    {
      id:4,
      title:"Introduction to Selenium Testing using Python",
      link:"https://joetechinsights.hashnode.dev/selenium-python",
      image:"https://portfolio20072023.s3.ap-south-1.amazonaws.com/python.avif",
      readTime:"5min"
    }
  ]
  
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionHeadText}>MyBlogs</p>
      </motion.div>
      <div className="mt-20 xs:pr-5 flex flex-wrap justify-between gap-7">
        {blogs.map((blog, i) => (
          <BlogCard key={`blog-${i}`} index={i} {...blog}/>
        ))}
      </div>
    </>
  );
};

export default SectionWrapper(Blog, "blog");
