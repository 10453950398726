import React, { useEffect, useRef, useState } from "react";
import { SectionWrapper } from "../section";
import { motion } from "framer-motion";
import { styles } from "../styles";
import { slideIn, textVariant } from "../utils/motion";
import { db } from "../firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { Carousel } from "@material-tailwind/react";
import Lottie from "react-lottie";
import * as animationData from "../Testimonial.json";

const Testimonials = () => {
  const formRef = useRef();
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newTestimonial, setNewTestimonial] = useState({
    Name: "",
    Role: "",
    Feedback: "",
    Company: "",
  });
  const [refresh, setRefresh] = useState(false);

  const defaultOptions = {
    loop: true,
    autoPlay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const fetchTestimonials = async () => {
    const testimonialsCollection = collection(db, "Testimonials");
    const testimonialsSnapshot = await getDocs(testimonialsCollection);
    const testimonialsList = testimonialsSnapshot.docs.map((doc) => doc.data());
    setTestimonials(testimonialsList);
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);
  console.log(testimonials);

  useEffect(() => {
    if (refresh) {
      fetchTestimonials();
      setRefresh(false);
    }
  }, [refresh]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTestimonial({ ...newTestimonial, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const testimonialsCollection = collection(db, "Testimonials");
      await addDoc(testimonialsCollection, newTestimonial);
      setTestimonials([...testimonials, newTestimonial]);
      setNewTestimonial({
        Name: "",
        Role: "",
        Feedback: "",
        Company: "",
      });
      setRefresh(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <motion.div variants={textVariant()}>
            <p className={styles.sectionSubText}>
              What People say about my Works
            </p>
            <h2 className={styles.sectionHeadText}>Testimonials</h2>
          </motion.div>
        </div>
      </div>
      <div
        className={`xl:mt-10 flex xl:flex-row flex-col gap-24 xs:gap-20 xs:pr-5 overflow-hidden`}
      >
        <motion.div
          className="flex flex-col h-max w-1/2 xs:w-full"
          variants={slideIn("left", "tween", 0.2, 1)}
        >
          <div className="w-[100%] h-28">
            <Lottie options={defaultOptions} />
          </div>
          <div className="flex flex-col w-full h-full bg-black-100 p-4 rounded-2xl">
            <Carousel autoplay loop nextArrow={""} prevArrow={""} navigation={""}>
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-center p-10 xs:p-0 h-full gap-8"
                >
                  <div className="flex text-start w-full xs:text-xl font-custom text-3xl">
                    &#34;{testimonial?.Feedback}&#34;
                  </div>
                  <div className="flex flex-col w-full items-end text-left">
                    <div className="flex text-2xl font-font">
                      {testimonial?.Name}
                    </div>
                    <div className="font-font text-lg">{testimonial?.Role}</div>
                    <div className="font-font text-lg">
                      {testimonial?.Company}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </motion.div>
        <motion.div
          variants={slideIn("right", "tween", 0.2, 1)}
          className="flex flex-col gap-4 w-full justify-center bg-black-100 p-8 rounded-2xl"
        >
          <div className="flex text-5xl font-font justify-center">Feedback</div>
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="flex flex-col w-full gap-4"
          >
            <label className="flex flex-col font-font">
              <span className="text-white font-font font-medium text-lg mb-2">
                Name
              </span>
              <input
                type="text"
                name="Name"
                value={newTestimonial.Name}
                onChange={handleInputChange}
                placeholder="Enter your Name"
                className="bg-tertiary font-font py-3 px-4 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              />
            </label>
            <label className="flex flex-col font-font">
              <span className="text-white font-font text-lg font-medium mb-2">
                Role
              </span>
              <input
                type="text"
                name="Role"
                value={newTestimonial.Role}
                onChange={handleInputChange}
                placeholder="Your Current Role"
                className="bg-tertiary py-3 px-4 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              />
            </label>
            <label className="flex flex-col font-font">
              <span className="text-white font-font text-lg font-medium mb-2">
                Company
              </span>
              <input
                type="text"
                name="Company"
                value={newTestimonial.Company}
                onChange={handleInputChange}
                placeholder="Your Current Company"
                className="bg-tertiary py-3 px-4 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              />
            </label>
            <label className="flex flex-col font-font">
              <span className="text-white font-font text-lg font-medium mb-2">
                Your Feedback
              </span>
              <textarea
                rows={4}
                name="Feedback"
                value={newTestimonial.Feedback}
                onChange={handleInputChange}
                placeholder="Write your feedback"
                className="bg-tertiary py-3 px-4 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              />
            </label>

            <button
              type="submit"
              className="bg-tertiary font-font text-lg py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary"
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </motion.div>
      </div>
    </>
  );
};

export default SectionWrapper(Testimonials, "testimonials");
