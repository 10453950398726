// import BallCanvas from "./canvas/Ball";
import { SectionWrapper } from "../section";
import { styles } from "../styles";
import { technologies } from "../constants/index";
import { motion } from "framer-motion";
import { fadeIn } from "../utils/motion";

const Tech = ({index}) => {
    return(
      <>
        <motion.div variants={fadeIn("right", "spring", index * 0.5, 0.75)}>
        <h2 className={styles.sectionHeadText}>My Skills</h2>
        </motion.div>
        <div className="flex flex-row flex-wrap justify-center pt-8 gap-10">
          {technologies.map((technology) => (
            <div className="flex flex-col items-center gap-8 justify-center w-20" key={technology.name}>
              <img className="w-full h-full object-contain" src={technology.icon} alt=""/>
              {/* <BallCanvas icon={technology.icon} /> */}
              <div className="flex w-max justify-center text-base text-center">{technology.name}</div>
            </div>
          ))}
        </div>
      </>
    )
}
export default SectionWrapper(Tech,"skills")