import { motion } from "framer-motion";
import axios from "axios";
import { styles } from "../styles";
import fileDownload from "js-file-download";

const Hero = () => {

  const handleDownload = async(url, filename) => {
    axios.get(url,{
      responseType:'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }
  const defaultImage = "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Profile1.JPG"
  // const hoveredImage = "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Profile2.jpg"

  return (
    <section className={`relative w-full h-screen mx-auto`}>
      <div
        className={`absolute inset-0 top-[120px] pb-20 max-w-7xl mx-auto ${styles.paddingX} flex flex-row xs:flex-col items-start gap-5`}
      >
        <div className="flex flex-row gap-4 w-full xs:w-[100%]">
          <div className="flex flex-col justify-center items-center mt-5">
            <div className="w-5 h-5 rounded-full bg-[#915EFF]" />
            <div className="w-1 sm:h-80 h-40 violet-gradient" />
          </div>

          <div className="flex flex-col w-full">
            <h1 className={`${styles.heroHeadText} text-white`}>
              Hi, I'm <span className="text-[#915EFF]">Joel</span>
            </h1>
            <p className={`${styles.heroSubText} mt-2 text-white-100`}>
              I develop end-to-end web applications<br className="sm:block hidden" />
              and user interfaces
            </p>
            <button
              className={`flex text-white md:text-[20px] sm:text-[18px] xs:text-[16px] tracking-wider rounded-md w-max mt-8 px-4 py-2 bg-[#915EFF]`} 
              onClick={() => {handleDownload('https://portfolio20072023.s3.ap-south-1.amazonaws.com/Updated+Resume.pdf', 'Updated+Resume.pdf')}}
            >
              Resume
            </button>
          </div>
        </div>
        <div className="flex w-full justify-center">
        <div className="flex w-full">
          <img 
            className="w-[500px] h-[650px] xs:h-[500px] xs:w-[100%]" 
            src={defaultImage} 
            alt=""
            />
        </div>
        </div>
      </div>      
      <div className="absolute xs:bottom-10 bottom-32 w-full flex justify-center items-center">
        <a href="#about">
          <div className="w-[35px] h-[64px] rounded-3xl border-4 border-secondary flex justify-center items-start p-2">
            <motion.div
              animate={{
                y: [0, 24, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
              className="w-3 h-3 rounded-full bg-secondary mb-1"
            />
          </div>
        </a>
      </div>
    </section>
  );
};

export default Hero;