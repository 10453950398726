import React from "react";
import Tilt from "react-tilt";
import { motion } from "framer-motion";
import { styles } from "../styles";
import { SectionWrapper } from "../section";
import { Projects } from "../constants";
import { fadeIn, textVariant } from "../utils/motion";

const ProjectCard = ({
  index,
  name,
  description,
  tags,
  image,
  github_src,
  site_url,
}) => (
  <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
    <Tilt
      opions={{ max: 45, scale: 1, speed: 450 }}
      className="bg-tertiary p-4 rounded-2xl sm:w-[360px] w-full"
    >
      <div className="relative w-full h-[230px]">
        <img
          src={image}
          alt=""
          className="w-full h-full object-cover rounded-2xl"
        />
        <div className="inset-0 flex justify-end m-3 card-img_hover">
          {/* <div
            onClick={() => window.open(site_url, "_blank", "noreferrer")}
            className="black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
          >
            <img
              src="https://portfolio20072023.s3.ap-south-1.amazonaws.com/preview.png"
              alt=""
              className="w-2/3 h-2/3 object-contain"
            />
          </div> */}
          <div
            onClick={() => window.open(github_src, "_blank", "noreferrer")}
            className="black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer ml-2 hover:scale-125"
          >
            <img
              src="https://portfolio20072023.s3.ap-south-1.amazonaws.com/github.png"
              alt=""
              className="w-1/2 h-1/2 object-contain"
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <h3 className="text-white font-bold text-[24px]">{name}</h3>
        <p className="mt-2 text-secondary text-[14px]">{description}</p>
      </div>
      <div className="mt-4 flex flex-wrap gap-2">
        {tags.map((tag) => (
          <p key={index + tag.name} className={`text-[14px] ${tag.color}`}>
            #{tag.name}
          </p>
        ))}
      </div>
    </Tilt>
  </motion.div>
);

const Works = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionSubText}>MyWork</p>
        <h2 className={styles.sectionHeadText}>Projects</h2>
      </motion.div>
      <div className="w-full flex xs:pr-5">
        <motion.p
          variants={fadeIn("", "", 0.1, 1)}
          className="mt-3 text-secondary text-[17px] max-w-3xl leading-[30px]"
        >
          Following projects showcases my skills and experience through
          real-world examples of my work. Each project is briefly described with
          links to code repositories. It reflects my
          ability to solve complex problems, working with different technologies and languages,
          and manage and document the projects effectively.
        </motion.p>
      </div>
      <div className="mt-20 flex flex-wrap gap-7 xs:pr-5">
        {Projects.map((project, i) => (
          <ProjectCard key={`project-${i}`} index={i} {...project}/>
        ))}
      </div>
    </>
  );
};
export default SectionWrapper(Works, "projects");