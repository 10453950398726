import { useState, useRef } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { styles } from "../styles";
import { Snackbar } from "@mui/material";
import { SectionWrapper } from "../section";
import { slideIn } from "../utils/motion";


const Contact = () => {
  const formRef = useRef();
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        `${process.env.REACT_APP_EMAIL_SERVICE_ID}`,
        `${process.env.REACT_APP_TEMPLATE_ID}`,
        {
          from_name: form.name,
          to_name: "Joel Revington",
          from_email: form.email,
          to_email: "joelrevington28@gmail.com",
          message: form.message,
        },
        `${process.env.REACT_APP_EMAILJS_KEY}`
      )
      .then(
        () => {
          setLoading(false);
          setSnack(true);
          setSnackMessage("Response Submitted");
          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.log(error);
          setSnack(true);
          setSnackMessage("Something Went Wrong");
        }
      );
  };

  return (
    <>
    <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snack}
          onClose={() => setSnack(false)}
          message={snackMessage}
          autoHideDuration={2000}
    />
    <div
      className={`xl:mt-10 flex xl:flex-row flex-col-reverse gap-36 xs:pr-5 xs:gap-20 overflow-hidden`}
    >
      <motion.div
        variants={slideIn("left", "tween", 0.2, 1)}
        className="flex-[0.75] bg-black-100 p-8 xs:p-4 rounded-2xl"
      >
        <p className={styles.sectionSubText}>Get in touch</p>
        <h3 className={styles.sectionHeadText}>Contact.</h3>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="mt-12 flex flex-col gap-8"
        >
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your Name</span>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="What's your name?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your email</span>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="What's your email?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your Message</span>
            <textarea
              rows={7}
              name="message"
              value={form.message}
              onChange={handleChange}
              placeholder="What do you want to say?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
            />
          </label>

          <button
            type="submit"
            className="bg-tertiary py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary"
          >
            {loading ? "Sending..." : "Send"}
          </button>
        </form>
      </motion.div>

      <motion.div
        variants={slideIn("right", "tween", 0.2, 1)}
        className="xl:flex-1 xl:h-auto pt-10 px-10 xs:px-2 md:h-[500px] h-[350px] xs:h-auto flex flex-col items-center"
      >
        <div className="flex text-white text-5xl xs:text-4xl font-semibold">Follow Me</div>
        <ul className="flex flex-row gap-12 xs:gap-4 flex-wrap mt-3 py-10 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a
            className="flex flex-row gap-5 items-center"
              href="https://www.linkedin.com/in/joel-revington-105994135"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 h-12"
                src="https://portfolio20072023.s3.ap-south-1.amazonaws.com/Linkedin.png"
                alt=""
              />
            </a>
          </li>
          <li>
            <a
            className="flex flex-row gap-5 items-center"
            href="https://twitter.com/JoelRevington15"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 h-12"
                src="https://portfolio20072023.s3.ap-south-1.amazonaws.com/Twitter.png"
                alt=""
              />
            </a>
          </li>
          <li>
            <a
            className="flex flex-row gap-5 items-center"
            href="https://www.instagram.com/joel_revington/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 h-12"
                src="https://portfolio20072023.s3.ap-south-1.amazonaws.com/Instagram.png"
                alt=""
              />
            </a>
          </li>
          <li>
            <a
            className="flex flex-row gap-5 items-center"
            href="https://github.com/JoelRevington1305"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 h-12"
                src="https://portfolio20072023.s3.ap-south-1.amazonaws.com/Github.svg"
                alt=""
              />
            </a>
          </li>
          <li>
            <a
            className="flex flex-row gap-5 items-center"
            href="https://www.facebook.com/profile.php?id=100009270750468"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 h-12"
                src="https://portfolio20072023.s3.ap-south-1.amazonaws.com/Facebook.png"
                alt=""
              />
            </a>
          </li>
        </ul>
        <div className="flex text-white text-5xl xs:text-4xl font-semibold">
          Contact Me
        </div>
        <div className="flex py-10 flex-row">
          <div className="flex text-2xl">Mobile :&nbsp;</div>
          <div className="flex text-2xl">+91-9150998582</div>
        </div>
      </motion.div>
    </div>
    </> 
  );
};

export default SectionWrapper(Contact, "contact");
