
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { styles } from "../styles";
import { navLinks } from "../constants";
// import axios from "axios";
// import fileDownload from "js-file-download";
// import { logo, menu, close } from "../assets";

const Navbar = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // const handleDownload = async(url, filename) => {
  //   axios.get(url,{
  //     responseType:'blob',
  //   })
  //   .then((res) => {
  //     fileDownload(res.data, filename)
  //   })
  // }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
        setActive("");
      }
    };

    window.addEventListener("scroll", handleScroll);

    const navbarHighlighter = () => {
      const sections = document.querySelectorAll("section[id]");

      sections.forEach((current) => {
        const sectionId = current.getAttribute("id");
        const sectionHeight = current.offsetHeight;
        const sectionTop =
          current.getBoundingClientRect().top - sectionHeight * 0.2;

        if (sectionTop < 0 && sectionTop + sectionHeight > 0) {
          setActive(sectionId);
        }
      });
    };

    window.addEventListener("scroll", navbarHighlighter);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", navbarHighlighter);
    };
  }, []);

  return (
    <nav
      className={`${
        styles.paddingX
      } w-full flex items-center py-5 fixed top-0 z-20 ${
        scrolled ? "bg-primary" : "bg-transparent"
      }`}
    >
      <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
        <Link
          to="/"
          className="flex items-center gap-2"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          {/* <img src={""} alt="logo" className="w-9 h-9 object-contain" /> */}
          <div className="text-white text-[18px] font-bold cursor-pointer flex ">
            Joel Revington&nbsp;
            <span className="sm:block hidden"> | Portfolio</span>
          </div>
        </Link>

        <ul className="list-none flex flex-row xs:hidden gap-10">
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              className={`${
                active === nav.id ? "text-white" : "text-secondary"
              } hover:text-white text-[18px] font-medium cursor-pointer`}
            >
              <a href={`#${nav.id}`}>{nav.title}</a>
            </li>
          ))}
            <button className="group relative text-[18px] inline-block overflow-hidden rounded text-secondary hover:text-white">
              <span className="ease absolute left-0 top-0 h-0 w-0 transition-all duration-200 group-hover:w-full"></span>
              <span className="ease absolute right-0 top-0 h-0 w-0 transition-all duration-200 group-hover:h-full"></span>
              <span className="ease absolute bottom-0 right-0 h-0 w-0 transition-all duration-200 group-hover:w-full"></span>
              <span className="ease absolute bottom-0 left-0 h-0 w-0 transition-all duration-200 group-hover:h-full"></span>
              {/* <div onClick={() => {handleDownload('https://portfolio20072023.s3.ap-south-1.amazonaws.com/Updated+Resume.pdf','Updated+Resume.pdf')}}>Resume</div> */}
            </button>
        </ul>

        <div className="hidden xs:flex flex-1 justify-end xs:pr-4 items-center">
          <img
            src={toggle ? "https://portfolio20072023.s3.ap-south-1.amazonaws.com/close.svg" : "https://portfolio20072023.s3.ap-south-1.amazonaws.com/Menu.svg"}
            alt="menu"
            className="w-[28px] h-[28px] object-contain"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col gap-4">
              {navLinks.map((nav) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.id ? "text-white" : "text-secondary"
                  }`}
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                >
                  <a href={`#${nav.id}`}>{nav.title}</a>
                </li>
              ))}
              <div 
                className="font-poppins font-medium cursor-pointer text-[16px] text-secondary hover:text-white"
                  // onClick={() => {handleDownload('https://portfolio20072023.s3.ap-south-1.amazonaws.com/Updated+Resume.pdf', 'Updated+Resume.pdf')}}
              >
                Resume
              </div>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;


// import React from "react";
// import {useHistory} from 'react-router-dom';

// const Navbar = () => {
//   const history = useHistory();
//   return (
//     <>
//     <div className="w-full flex flex-row py-4 bg-sky-100">
//       <div className="w-1/3 flex justify-around sm:w-1/5">
//         {/* <img className="w-14 h-14 sm:w-10 sm:h-10" src="https://portfolio20072023.s3.ap-south-1.amazonaws.com/Logo.png" alt="" /> */}
//       </div>
//       <div className="flex flex-row gap-14 text-black w-1/3 sm:w-4/5 justify-center items-center font-semibold text-xl">
//         <div className="hover:cursor-pointer hover:underline" onClick={() => history.push('/home')}>
//           Home
//         </div>
//         <div className="hover:cursor-pointer hover:underline" onClick={() => history.push('/about')}>
//           About
//         </div>
//         {/* <div>Blog</div> */}
//         <div className="hover:cursor-pointer hover:underline" onClick={() => history.push('/contact')}>
//           Contact
//         </div>
//       </div>
//       <div className="flex px-4 w-1/3 justify-center items-center visible sm:hidden">
//         {/* <button className="flex px-3 py-1 h-max rounded-md bg-black text-sky-100 text-lg hover:opacity-95 font-semibold">
//           Hire Me
//         </button> */}
//       </div>
//     </div>
//     </>
//   );
// };

// export default Navbar;
