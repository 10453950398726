import React from "react";
import Tilt from "react-tilt";
import { motion } from "framer-motion";

import { styles } from "../styles";
import { services } from "../constants";
import { SectionWrapper } from "../section";
import { fadeIn, textVariant } from "../utils/motion";

const ServiceCard = ({ index, title, img }) => (
  <Tilt className="xs:w-[250px] w-full">
    <motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card"
    >
      <div
        options={{
          max: 45,
          scale: 1,
          speed: 450,
        }}
        className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col"
      >
        <img
          src={img}
          alt="web-development"
          className="w-20 h-20 object-contain"
        />

        <h3 className="text-white text-[20px] font-bold text-center">
          {title}
        </h3>
      </div>
    </motion.div>
  </Tilt>
);

const About = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionSubText}>Introduction</p>
        <h2 className={styles.sectionHeadText}>Overview.</h2>
      </motion.div>

      <motion.div
        variants={fadeIn("", "", 0.1, 1)}
        className="mt-4 text-secondary text-[17px] max-w-3xl xs:pr-2 leading-[30px]"
      >
        <motion.div className="flex bottom-4 relative font-bold text-2xl">
            Developing With a Passion,
            While Exploring The World.
        </motion.div>
        Hello, I'm a passionate and dedicated full
        stack developer with a flair for building cutting-edge web
        applications. I thrive on solving complex problems and
        transforming ideas into functional and user-friendly digital
        experiences.
        <br/>
        As my curiosity grew, I delved deeper into programming languages
        and back-end development, realizing the power of combining both
        front-end and back-end expertise.
        {/* <br/> */}
        {/* As a developer with an insatiable curiosity, I embark on a
        journey of continuous learning and discovery. Join me as I
        share my experiences and showcase the projects that reflect my
        enthusiasm for technology and adventure. */}
        <br/>
        I'm a quick learner and collaborate closely with clients to
        create efficient, scalable, and user-friendly solutions that solve
        real-world problems. 
        {/* Let's work together to bring your ideas to life! */}
      </motion.div>

      <div className="mt-20 flex flex-row xs:flex-wrap xs:justify-center gap-10">
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service} />
        ))}
      </div>
    </>
  );
};

export default SectionWrapper(About, "about");